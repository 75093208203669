import React from "react";

import { AboutUs } from "../containers";

const About = () => {
	return (
		<>
			<div style={{ backgroundColor: "var(--color-bg)" }}>
				<AboutUs />
			</div>
		</>
	);
};

export default About;
