import React from "react";
import { Route, Routes } from "react-router-dom";
import "animate.css";
import "./App.css";

import { RootLayout, ServicesLayout } from "./layouts";
import { Home, Services, ServiceDetails, About, Contact, PageNotFound } from "./pages";

const App = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<RootLayout />}>
					<Route index element={<Home />} />
					<Route path="services" element={<ServicesLayout />}>
						<Route index element={<Services />} />
						<Route path=":label" element={<ServiceDetails />} />
					</Route>
					<Route path="about" element={<About />} />
					<Route path="contact" element={<Contact />} />
				</Route>

				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</>
	);
};

export default App;
