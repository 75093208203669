import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PopupButton } from "react-calendly";
import "./navbar.css";
import logo from "../../assests/logo.png";

import { MdOutlineDesignServices } from "react-icons/md";

const Navbar = () => {
	const [isMenuClicked, setIsMenuClicked] = useState(false);
	const [menuClass, setMenuClass] = useState("navbar-menu");
	const [visibility, setVisibility] = useState("hidden");

	const toggleMenu = () => {
		if (isMenuClicked) {
			setMenuClass("navbar-menu");
			setVisibility("hidden");
			document.body.style.height = "auto";
			document.body.style.overflowY = "auto";
		} else {
			setMenuClass("navbar-menu clicked");
			setVisibility("visible");
			document.body.style.height = "100vh";
			document.body.style.overflowY = "hidden";
		}
		setIsMenuClicked(!isMenuClicked);
	};

	const onMenuLinkClick = () => {
		document.body.style.height = "auto";
		document.body.style.overflowY = "auto";
		toggleMenu();
	};

	return (
		<nav className="Navbar section__padding">
			<div className="navbar-links">
				<NavLink to="/" className="navbar-links-logo">
					<img src={logo} alt="logo" />
					<p>Toe&Tails</p>
				</NavLink>
				<div className="navbar-links-container">
					<div className="links">
						<NavLink to="/">Home</NavLink>
						<NavLink to="/services">Services</NavLink>
						<NavLink to="/about">About</NavLink>
						<NavLink to="/contact">Contact</NavLink>
					</div>
					<PopupButton url="https://calendly.com/toeandtails/pet-services" rootElement={document.getElementById("root")} text="Book Now" className="animate__animated animate__tada"></PopupButton>
				</div>
				<div className={menuClass} onClick={toggleMenu}>
					<div className="menu"></div>
				</div>
			</div>
			<div className={`navbar-menu-links-container ${visibility}`}>
				<div className="menu-links">
					<div className="button-container">
						<PopupButton url="https://calendly.com/toeandtails/pet-services" rootElement={document.getElementById("root")} onClick={onMenuLinkClick} text="Book Now"></PopupButton>
						<MdOutlineDesignServices className="book-icon" />
					</div>
					<NavLink to="/" onClick={onMenuLinkClick}>
						Home
					</NavLink>
					<NavLink to="/services" onClick={onMenuLinkClick}>
						Services
					</NavLink>
					<NavLink to="/about" onClick={onMenuLinkClick}>
						About
					</NavLink>
					<NavLink to="/contact" onClick={onMenuLinkClick}>
						Contact
					</NavLink>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
