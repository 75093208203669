import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Lottie from "lottie-react";
import emailjs from "@emailjs/browser";
import "./contactUs.css";

import loading from "../../assests/loading.json";
import emailSent from "../../assests/email-sent.json";
import emailNotSent from "../../assests/email-not-sent.json";

const ContactUs = () => {
	const { ref: heading, inView: isHeadingVisible } = useInView();

	const form = useRef();
	const textareaRef = useRef();
	const loadingRef = useRef();
	const emailSentRef = useRef();
	const emailNotSentRef = useRef();

	const MY_SERVICE_ID = process.env.REACT_APP_MY_SERVICE_ID;
	const MY_TEMPLATE_ID = process.env.REACT_APP_MY_TEMPLATE_ID;
	const MY_PUBLIC_KEY = process.env.REACT_APP_MY_PUBLIC_KEY;

	const [headingClasses, setHeadingClasses] = useState("");
	const [isLoadingAnimationRunning, setIsLoadingAnimationRunning] = useState(false);
	const [isEmailSentAnimationRunning, setIsEmailSentAnimationRunning] = useState(false);
	const [isEmailNotSentAnimationRunning, setIsEmailNotSentAnimationRunning] = useState(false);
	const [details, setDetails] = useState({
		first_name: "",
		last_name: "",
		email: "",
		address: "",
		message: "",
	});

	const onInputChange = e => {
		const { name, value } = e.target;
		setDetails(details => {
			return { ...details, [name]: value };
		});
	};

	const onFormSubmit = e => {
		e.preventDefault();

		document.body.style.height = "100vh";
		document.body.style.overflowY = "hidden";

		setIsLoadingAnimationRunning(true);

		emailjs
			.send(MY_SERVICE_ID, MY_TEMPLATE_ID, details, MY_PUBLIC_KEY)
			.then(result => {
				console.log(result.text);
				setIsLoadingAnimationRunning(false);
				setIsEmailSentAnimationRunning(true);
				emailSentRef.current.play();
			})
			.catch(err => {
				console.log(err);
				setIsLoadingAnimationRunning(false);
				setIsEmailNotSentAnimationRunning(true);
				emailNotSentRef.current.play();
			});

		setDetails({
			first_name: "",
			last_name: "",
			email: "",
			address: "",
			message: "",
		});
	};

	const onAnimationComplete = name => {
		if (name === "email-sent") {
			setIsEmailSentAnimationRunning(false);
			emailSentRef.current.goToAndStop(0);
		}
		if (name === "email-not-sent") {
			setIsEmailNotSentAnimationRunning(false);
			emailNotSentRef.current.goToAndStop(0);
		}
		document.body.style.height = "auto";
		document.body.style.overflowY = "auto";
	};

	useEffect(() => {
		if (isHeadingVisible) setHeadingClasses("animate__fadeIn");
		else setHeadingClasses("");
	}, [isHeadingVisible]);

	useEffect(() => {
		textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
	}, [textareaRef]);

	useEffect(() => {
		emailNotSentRef.current.setSpeed(2);
		emailNotSentRef.current.playSegments([0, 180], true);
		loadingRef.current.setDirection(-1);
		loadingRef.current.setSpeed(1.5);
		emailSentRef.current.pause();
		emailNotSentRef.current.pause();
	}, []);

	return (
		<>
			<div className="ContactUs">
				<div className="content section__padding">
					<h1 ref={heading} className={`heading animate__animated animate__slower fadeIn ${headingClasses}`}>
						We would love to hear from you. Please fill out the form below and we'll get back to you as soon as possible.
					</h1>
					<form ref={form} class="contact-form" onSubmit={onFormSubmit}>
						<div className="input-details">
							<label htmlFor="first_name">First Name*</label>
							<input type="text" name="first_name" id="first_name" value={details.first_name} onChange={onInputChange} required />
						</div>
						<div className="no-break"></div>
						<div className="input-details">
							<label htmlFor="last_name">Last Name*</label>
							<input type="text" name="last_name" id="last_name" value={details.last_name} onChange={onInputChange} required />
						</div>
						<div className="break"></div>
						<div className="input-details">
							<label htmlFor="email">Email*</label>
							<input type="email" name="email" id="email" value={details.email} onChange={onInputChange} required />
						</div>
						<div className="no-break"></div>
						<div className="input-details">
							<label htmlFor="address">Address</label>
							<input type="text" name="address" id="address" value={details.address} onChange={onInputChange} />
						</div>
						<div className="break"></div>
						<div className="input-details">
							<label htmlFor="message">Message*</label>
							<textarea name="message" id="message" rows={3} ref={textareaRef} value={details.message} onChange={onInputChange} required />
						</div>
						<div className="break"></div>
						<button type="submit" className="submit-button">
							Submit
						</button>
					</form>
				</div>
			</div>
			<div className={`animations ${isLoadingAnimationRunning || isEmailSentAnimationRunning || isEmailNotSentAnimationRunning ? "running" : ""}`}>
				<div className={`loading ${isLoadingAnimationRunning ? "current" : ""}`}>
					<Lottie lottieRef={loadingRef} animationData={loading}></Lottie>
				</div>
				<div className={`email-sent ${isEmailSentAnimationRunning ? "current" : ""}`}>
					<Lottie lottieRef={emailSentRef} animationData={emailSent} loop={false} onComplete={() => onAnimationComplete("email-sent")}></Lottie>
				</div>
				<div className={`email-not-sent ${isEmailNotSentAnimationRunning ? "current" : ""}`}>
					<Lottie lottieRef={emailNotSentRef} animationData={emailNotSent} loop={false} onComplete={() => onAnimationComplete("email-not-sent")}></Lottie>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
