import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./breadcrumbs.css";

const Breadcrumbs = () => {
	const location = useLocation();

	let currentLink = "";

	return (
		<div className="Breadcrumbs">
			{location.pathname
				.split("/")
				.filter(crumb => crumb !== "")
				.map(crumb => {
					currentLink += `/${crumb}`;
					return (
						<Link to={currentLink} key={crumb} className="crumb">
							<p>{crumb.toLocaleUpperCase()}</p>
						</Link>
					);
				})}
		</div>
	);
};

export default Breadcrumbs;
