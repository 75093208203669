import React from "react";
import { useParams } from "react-router-dom";

import { ServiceDetailsContainer } from "../../containers";

const ServiceDetails = () => {
	const { label } = useParams();

	return (
		<div style={{ backgroundColor: "var(--color-bg)" }}>
			<ServiceDetailsContainer label={label} />
		</div>
	);
};

export default ServiceDetails;
