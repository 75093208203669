import dog1 from "../assests/dog1.jpg";
import dog2 from "../assests/dog2.jpg";
import dog3 from "../assests/dog3.jpg";

const feedbacksApi = [
	{
		id: 1,
		author: "Priyanshu",
		content: "Exceptional Grooming Services: I had a great experience with Toe&Tails. The staff was professional, friendly, and delivered outstanding grooming services. My dog looked amazing and smelled fantastic. Highly recommended!",
		image: dog1,
	},
	{
		id: 2,
		author: "Sara",
		content: "Welcoming and Clean Environment: Toe&Tails provides a welcoming and clean environment. The staff was friendly, and the salon's attention to cleanliness was impressive. It made me feel confident in the care they provided. Great job!",
		image: dog2,
	},
	{
		id: 3,
		author: "Amit",
		content: "Knowledgeable and Skilled Staff: The staff of Toe&Tails are knowledgeable and skilled. They understood my dog's specific grooming needs and provided helpful advice. I appreciate their expertise and genuine care for the animals. Thank you!",
		image: dog3,
	},
];

export default feedbacksApi;
