import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "./serviceHome.css";

const ServiceHome = ({ label, title, image }) => {
	const { ref: service, inView: isServiceVisible } = useInView();

	const [serviceClasses, setServiceClasses] = useState("");

	useEffect(() => {
		if (isServiceVisible) setServiceClasses("animate__flipInY");
		else setServiceClasses("");
	}, [isServiceVisible]);

	return (
		<div className="ServiceHome">
			<div ref={service} className={`serviceCard animate__animated ${serviceClasses}`}>
				<NavLink to={`/services/${label}`}>
					<img src={image} alt={title} />
				</NavLink>
			</div>
			<p className="serviceName">{title}</p>
		</div>
	);
};

export default ServiceHome;
