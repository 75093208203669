import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./headerServices.css";

import { Breadcrumbs } from "../../components";

const HeaderServices = () => {
	const { ref: heading, inView: isHeadingVisible } = useInView();

	const [headingClasses, setHeadingClasses] = useState("");

	useEffect(() => {
		if (isHeadingVisible) setHeadingClasses("animate__fadeIn");
		else setHeadingClasses("");
	}, [isHeadingVisible]);

	return (
		<div className="HeaderServices section__padding">
			<Breadcrumbs />
			<h1 ref={heading} className={`heading animate__animated animate__slower fadeIn ${headingClasses}`}>
				We can't wait to see you!
			</h1>
		</div>
	);
};

export default HeaderServices;
