import React from "react";
import { Outlet } from "react-router-dom";

import { HeaderServices } from "./../containers";

const ServicesLayout = () => {
	return (
		<>
			<div style={{ backgroundColor: "var(--color-bg)" }}>
				<HeaderServices />
			</div>
			<Outlet />
		</>
	);
};

export default ServicesLayout;
