import dogsandcats1 from "../assests/dogsandcats1.jpg";
import dogsandcats2 from "../assests/dogsandcats2.jpg";
import dogsandcats3 from "../assests/dogsandcats3.jpg";
import dogsandcats4 from "../assests/dogsandcats4.jpg";
import dogsandcats5 from "../assests/dogsandcats5.jpg";

const aboutApi = [
	{
		id: 1,
		heading: "Welcome to Toe&Tails!",
		description: "At Toe&Tails, we are passionate about all things canine! We are a team of dedicated dog lovers who believe that dogs bring immense joy and happiness to our lives. Our goal is to provide you with a valuable resource that celebrates the unconditional love, loyalty, and companionship that dogs offer.",
		image: dogsandcats1,
	},
	{
		id: 2,
		heading: "Who We Are",
		description: "We are a group of dog enthusiasts who have come together to share our knowledge, experience, and love for dogs. Each member of our team has a unique background and expertise, ranging from professional dog trainers to experienced veterinarians. Together, we aim to create a platform that offers reliable information, helpful tips, and entertaining content to dog owners and enthusiasts alike.",
		image: dogsandcats2,
	},
	{
		id: 3,
		heading: "What We Do",
		description: "Our Dog Website is designed to be your go-to destination for all things dog-related. Whether you are a first-time dog owner or a seasoned dog lover, we strive to provide you with comprehensive resources that cover a wide range of topics. From dog care and training tips to health advice and breed information, we want to ensure that you have the knowledge and tools to give your furry friend the best possible care.",
		image: dogsandcats3,
	},
	{
		id: 4,
		heading: "Why We Do It",
		description: "Dogs are not just pets; they are cherished members of our families. We understand the joy and responsibility that comes with owning a dog, and we are committed to promoting their well-being. Our love for dogs drives us to create a supportive community where dog owners can connect, learn, and share their experiences. We believe that by providing accurate information and promoting responsible dog ownership, we can help strengthen the bond between humans and dogs.",
		image: dogsandcats4,
	},
	{
		id: 5,
		heading: "Our Mission",
		description: "Our mission is to be a trusted source of information and inspiration for dog owners and enthusiasts worldwide. We are dedicated to promoting responsible dog ownership, advocating for the well-being of all dogs, and fostering a loving and respectful relationship between humans and canines. Through our website, we aim to educate, entertain, and empower dog owners to provide their pets with the love, care, and training they deserve.",
		image: dogsandcats5,
	},
];

export default aboutApi;
