import React from "react";

import { Header, ServicesHome, Feedbacks } from "../containers";

const Home = () => {
	return (
		<>
			<div style={{ backgroundColor: "var(--color-bg)" }}>
				<Header />
			</div>
			<ServicesHome />
			<Feedbacks />
		</>
	);
};

export default Home;
