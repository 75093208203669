import boarding from "../assests/boarding.png";
import grooming from "../assests/grooming.png";
import walking from "../assests/walking.png";
import daycare from "../assests/daycare.png";
import dog from "../assests/dog.png";

const servicesApi = {
	boarding: {
		id: 1,
		title: "Boarding",
		image: boarding,
		details: [
			{
				id: 1.1,
				name: "Boarding",
				duration: "24 hours+",
				cost: "500 / Day",
				url: "https://calendly.com/toeandtails/pet-services",
			},
		],
	},
	grooming: {
		id: 2,
		title: "Grooming",
		image: grooming,
		details: [
			{
				id: 2.1,
				name: "Dog Grooming Basic Plan",
				description: "Shampoo + Conditioning + Towel Dry + Blow Dry",
				duration: "45 mins",
				cost: "399",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.2,
				name: "Dog Grooming Silver Plan",
				description: "Shampoo + Conditioning + Towel Dry + Blow Dry + End Up Massage + Ear wax cleaning + Paw wax + Cologne",
				duration: "60 mins",
				cost: "699",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.3,
				name: "Dog Grooming Golden Plan",
				description: "Shampoo + Conditioning + Towel Dry + Blow Dry + Hair Trimming + Nail Trimming + Ear Wax + Paw Wax + Tick Check + Colonge + Comfortor + End Up Massage",
				duration: "90 mins",
				cost: "999",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.4,
				name: "Dog Waterless Grooming Plan",
				description: "Golden plan + Without Water",
				duration: "90 mins",
				cost: "999",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.5,
				name: "Cat Grooming Basic Plan",
				description: "Shampoo + Conditioning + Towel Dry + Blow Dry + End Up Massage + Paw wax + Ear Wax",
				duration: "60 mins",
				cost: "699",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.6,
				name: "Cat Grooming Pro Plan",
				description: "Shampoo + Conditioning + Towel Dry + Blow Dry + Hair Trimming + Nail Trimming + Ear Wax + Paw Wax + Tick Check + Colonge + Comfortor + End Up Massage",
				duration: "90 mins",
				cost: "1099",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 2.7,
				name: "Cat Waterless Grooming Plan",
				description: "Pro plan + Without Water",
				duration: "90 mins",
				cost: "1099",
				url: "https://calendly.com/toeandtails/pet-services",
			},
		],
	},
	walking: {
		id: 3,
		title: "Walking",
		image: walking,
		details: [
			{
				id: 3.2,
				name: "Walking",
				duration: "45 mins Daily",
				cost: "700 / Week",
				url: "https://calendly.com/toeandtails/pet-services",
			},
		],
	},
	daycare: {
		id: 4,
		title: "Day Care",
		image: daycare,
		details: [
			{
				id: 4.1,
				name: "Day Care",
				duration: "12 hours",
				cost: "300",
				url: "https://calendly.com/toeandtails/pet-services",
			},
		],
	},
	adoption: {
		id: 5,
		title: "Adoption",
		image: dog,
		details: [
			{
				id: 5.1,
				name: "Pomeranian",
				duration: "Lifetime",
				cost: "4k-5k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.2,
				name: "German Shypherd",
				duration: "Lifetime",
				cost: "10k-12k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.3,
				name: "Labrador",
				duration: "Lifetime",
				cost: "8k-9k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.4,
				name: "Pug",
				duration: "Lifetime",
				cost: "10k-11k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.5,
				name: "Beagle",
				duration: "Lifetime",
				cost: "12k-15k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.6,
				name: "Shihtzu/lahasa apso",
				duration: "Lifetime",
				cost: "15k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.7,
				name: "Mastiff Breeds (English, French, Bull)",
				duration: "Lifetime",
				cost: "20k+",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.8,
				name: "Doberman",
				duration: "Lifetime",
				cost: "7k-10k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
			{
				id: 5.9,
				name: "More Pets/Breeds Available",
				duration: "Lifetime",
				cost: "500-50k",
				url: "https://calendly.com/toeandtails/pet-services",
			},
		],
	},
};

export default servicesApi;
