import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./header.css";
import dogandcat from "../../assests/dogandcat.png";

const Header = () => {
	const { ref: content, inView: isContentVisible } = useInView();
	const { ref: image, inView: isImageVisible } = useInView();

	const [contentClasses, setContentClasses] = useState("");
	const [imageClasses, setImageClasses] = useState("");

	useEffect(() => {
		if (isContentVisible) setContentClasses("animate__fadeIn");
		else setContentClasses("");
		if (isImageVisible) setImageClasses("animate__fadeIn");
		else setImageClasses("");
	}, [isContentVisible, isImageVisible]);

	return (
		<div className="Header">
			<div className="container section__padding">
				<div ref={content} className={`content animate__animated animate__slower fadeIn ${contentClasses}`}>
					<h1>Welcome to Toe&Tails</h1>
					<p>For a pawfect grooming experience.</p>
				</div>
				<div ref={image} className={`image animate__animated animate__slower fadeIn ${imageClasses}`}>
					<img src={dogandcat} alt="Dog and Cat" />
				</div>
			</div>
		</div>
	);
};

export default Header;
