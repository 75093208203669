import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../../assests/logo.png";

import { LiaCopyrightSolid } from "react-icons/lia";

const Footer = () => {
	return (
		<div className="Footer">
			<div className="details">
				<Link to="/" className="logo">
					<img src={logo} alt="logo" />
					<p>Toe&Tails</p>
				</Link>
				<div className="address">
					<h4>Address</h4>
					<p>
						<a href="https://www.google.com/maps/place/Bairhana,+Prayagraj,+Uttar+Pradesh/@25.4358029,81.8489698,17z/data=!4m6!3m5!1s0x399acaac7911edbb:0xe17fbab3ff48d774!8m2!3d25.4405198!4d81.8559025!16s%2Fg%2F1tflqdyp?entry=ttu" target="_blank" rel="noopener noreferrer">
							205/153a, Old Bairahana, Prayagraj
						</a>
					</p>
				</div>
				<div className="contact">
					<h4>Contact</h4>
					<p>
						<a href="mailto:ay6899169@gmail.com">ay6899169@gmail.com</a>
					</p>
					<p>
						<a href="tel:+91 9120078962">+91 9120078962</a>
					</p>
				</div>
				<div className="socials">
					<h4>Socials</h4>
					<p>
						<a href="https://instagram.com/toeandtails.live?igshid=MzNlNGNkZWQ4Mg==" target="_blank" rel="noopener noreferrer">
							Instagram
						</a>
					</p>
				</div>
			</div>
			<div className="copyright">
				<p>
					<LiaCopyrightSolid /> 2023 by Toe&Tails
				</p>
			</div>
		</div>
	);
};

export default Footer;
