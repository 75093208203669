import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./aboutUs.css";

import aboutApi from "../../apis/aboutApi";

const Section = ({ heading, description, image }) => {
	const { ref: textRef, inView: isTextVisible } = useInView();

	const [textClasses, setTextClasses] = useState("");

	useEffect(() => {
		if (isTextVisible) setTextClasses("animate__fadeIn");
		else setTextClasses("");
	}, [isTextVisible]);

	return (
		<div className="section section__padding">
			<div ref={textRef} className={`text animate__animated animate__slower fadeIn ${textClasses}`}>
				<h1 className="heading">{heading}</h1>
				<p className="description">{description}</p>
			</div>
			<div className="image">
				<img src={image} alt={heading} />
			</div>
		</div>
	);
};

const AboutUs = () => {
	return (
		<div className="AboutUs">
			<div className="content">
				{aboutApi.map(about => {
					const { id, heading, description, image } = about;
					return <Section key={id} heading={heading} description={description} image={image} />;
				})}
			</div>
		</div>
	);
};

export default AboutUs;
