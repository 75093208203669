import React, { useEffect } from "react";
import { PopupButton } from "react-calendly";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./serviceDetailsContainer.css";

import { BsInfoCircle } from "react-icons/bs";

import servicesApi from "../../apis/servicesApi";

const ServiceDetails = ({ name, description, duration, cost, url }) => {
	return (
		<div className="ServiceDetails">
			<h2 className="service-name">
				<span>{name}</span>
				{description && (
					<>
						<span className="tooltip">
							<Tippy content={description} className="text">
								<span>
									<BsInfoCircle />
								</span>
							</Tippy>
						</span>
					</>
				)}
			</h2>
			<div className="service-details">
				<div className="service-cost-and-duration">
					<p className="duration">{duration}</p>
					<p className="cost">{cost}</p>
				</div>
				<PopupButton url={url} rootElement={document.getElementById("root")} text="Book Now" className="bookButton"></PopupButton>
			</div>
		</div>
	);
};

const ServiceDetailsContainer = ({ label }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ServiceDetailsContainer section__padding">
			{!servicesApi[label] ? (
				<div>Service doesn't exist.</div>
			) : (
				servicesApi[label].details.map(details => {
					const { id, name, description, duration, cost, url } = details;
					return <ServiceDetails key={id} name={name} description={description} duration={duration} cost={cost} url={url} />;
				})
			)}
		</div>
	);
};

export default ServiceDetailsContainer;
