import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { PopupButton } from "react-calendly";
import "./servicesHome.css";

import { ServiceHome } from "../../components";

import servicesApi from "./../../apis/servicesApi";

const ServicesHome = () => {
	const { ref: heading, inView: isHeadingVisible } = useInView();
	const { ref: bookButton, inView: isBookButtonVisible } = useInView();

	const [headingClasses, setHeadingClasses] = useState("");
	const [bookButtonClasses, setBookButtonClasses] = useState("");

	useEffect(() => {
		if (isHeadingVisible) setHeadingClasses("animate__fadeIn");
		else setHeadingClasses("");
		if (isBookButtonVisible) setBookButtonClasses("animate__pulse");
		else setBookButtonClasses("");
	}, [isHeadingVisible, isBookButtonVisible]);

	return (
		<div className="ServicesHome section__xlarge_padding">
			<h1 ref={heading} className={`heading animate__animated animate__slower fadeIn ${headingClasses}`}>
				Our Services
			</h1>
			<div className="services">
				{Object.entries(servicesApi).map(([label, service]) => {
					const { id, title, image } = service;
					return <ServiceHome key={id} label={label} title={title} image={image} />;
				})}
			</div>
			<div ref={bookButton}>
				<PopupButton url="https://calendly.com/toeandtails/pet-services" rootElement={document.getElementById("root")} text="Book Now" className={`bookButton animate__animated ${bookButtonClasses}`}></PopupButton>
			</div>
		</div>
	);
};

export default ServicesHome;
