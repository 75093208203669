import React from "react";

import { ContactUs } from "../containers";

const Contact = () => {
	return (
		<div style={{ backgroundColor: "var(--color-bg)" }}>
			<ContactUs />
			<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3603.073596775519!2d81.8489698194534!3d25.4358029061217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399acaac7911edbb%3A0xe17fbab3ff48d774!2sBairhana%2C%20Prayagraj%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1688593535553!5m2!1sen!2sin" height="450" style={{ border: 0, width: "100vw" }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>
	);
};

export default Contact;
