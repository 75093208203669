import React from "react";

import { ServicesContainer } from "../../containers";

const Services = () => {
	return (
		<div style={{ backgroundColor: "var(--color-bg)" }}>
			<ServicesContainer />
		</div>
	);
};

export default Services;
