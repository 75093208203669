import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./feedbacks.css";

import { FeedbackCard } from "../../components";

import feedbacksApi from "./../../apis/feedbacksApi";

const Feedbacks = () => {
	const { ref: heading, inView: isHeadingVisible } = useInView();

	const [headingClasses, setHeadingClasses] = useState("");

	useEffect(() => {
		if (isHeadingVisible) setHeadingClasses("animate__fadeIn");
		else setHeadingClasses("");
	}, [isHeadingVisible]);

	return (
		<div className="Feedbacks section__large_padding">
			<h1 ref={heading} className={`heading animate__animated animate__slower fadeIn ${headingClasses}`}>
				What Our Happy Clients Say
			</h1>
			<div className="feedbacks-container">
				{feedbacksApi.map(feedback => {
					const { id, author, content, image } = feedback;
					return <FeedbackCard key={id} author={author} content={content} image={image} />;
				})}
			</div>
		</div>
	);
};

export default Feedbacks;
