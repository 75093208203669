import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./servicesContainer.css";

import servicesApi from "../../apis/servicesApi";

const Service = ({ label, title }) => {
	return (
		<div className="Service">
			<h1 className="title">{title}</h1>
			<Link to={label}>
				<button type="button" className="exploreButton">
					Explore
				</button>
			</Link>
		</div>
	);
};

const ServicesContainer = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ServicesContainer section__padding">
			{Object.entries(servicesApi).map(([label, service]) => {
				const { id, title } = service;
				return <Service key={id} label={label} title={title} />;
			})}
		</div>
	);
};

export default ServicesContainer;
