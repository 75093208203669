import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
	return (
		<div style={{ backgroundColor: "var(--color-bg)", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "200%" }}>
			<h1 style={{ fontFamily: "var(--font-family-heading)" }}>404</h1>
			<p style={{ fontFamily: "var(--font-family-body)" }}>Page Not Found</p>
			<Link to="/" style={{ fontFamily: "var(--font-family-body)", fontSize: "70%", color: "var(--color-text)", textDecoration: "underline" }}>
				Go To Home
			</Link>
		</div>
	);
};

export default PageNotFound;
