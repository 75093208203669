import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./feedbackCard.css";

const FeedbackCard = ({ author, content, image }) => {
	const { ref: feedbackCard, inView: isFeedbackCardVisible } = useInView();

	const [feedbackCardClasses, setFeedbackCardServiceClasses] = useState("");

	useEffect(() => {
		if (isFeedbackCardVisible) setFeedbackCardServiceClasses("animate__rubberBand");
		else setFeedbackCardServiceClasses("");
	}, [isFeedbackCardVisible]);

	return (
		<div ref={feedbackCard} className={`FeedbackCard animate__animated ${feedbackCardClasses}`}>
			<div className="image">
				<img src={image} alt="Dog Profile" />
			</div>
			<div className="content">"{content}"</div>
			<h3 className="author">- {author}</h3>
		</div>
	);
};

export default FeedbackCard;
