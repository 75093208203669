import React from "react";
import { Outlet } from "react-router-dom";

import { Navbar } from "../components";
import { Footer } from "../containers";

const RootLayout = () => {
	return (
		<>
			<div style={{ backgroundColor: "var(--color-bg)" }}>
				<Navbar />
			</div>
			<Outlet />
			<Footer />
		</>
	);
};

export default RootLayout;
